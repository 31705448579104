import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Structure, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Contact us | Asvata Olare Bar
			</title>
			<meta name={"description"} content={"Have questions or want to reach out to us? Our team at Asvata Olare Bar is here to assist you."} />
			<meta property={"og:title"} content={"Contact us | Asvata Olare Bar"} />
			<meta property={"og:description"} content={"Have questions or want to reach out to us? Our team at Asvata Olare Bar is here to assist you."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/OGimage.png?v=2021-09-23T20:32:10.363Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/favicon.png?v=2021-09-23T20:33:18.314Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/270.png?v=2021-09-23T20:33:32.819Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header />
		<Section sm-display="block" margin="0 0 15% 0">
			<Box min-width="100px" min-height="100px">
				<Text margin="80px 0px 50px 0px" font="--headline1">
					Contact us
				</Text>
				<Text margin="0px 0px 0px 0px" font="--lead">
					Have questions or want to reach out to us? Our team at Asvata Olare Bar is here to assist you. Whether you want to book a table for an upcoming game or inquire about our special offers, you can easily get in touch with us. Join now to experience the best sports bar in Delhi and discover why we're known for providing great opportunities to have fun.{"\n\n"}
				</Text>
			</Box>
			<Structure
				sm-display="block"
				sm-align-items="center"
				sm-align-self="flex-start"
				sm-justify-items="center"
				sm-flex="0 0 auto"
				sm-width="100% border-box"
			>
				<Override
					slot="Content"
					order="-1"
					display="grid"
					justify-content="center"
					min-width={0}
					width="0%"
					sm-display="inline-block"
				>
					<Override slot="cell-0">
						<Text margin="0px 0px 0px 0px" font="--headline3">
							Adress
							<br />
						</Text>
						<Text margin="0px 0px 0px 0px" min-width="300px">
							2, Ground Floor and First Floor, Pocket 7 Ashish Plaza, Sector 12 Dwarka, New Delhi, Delhi 110075
						</Text>
					</Override>
					<Override slot="cell-1">
						<Text margin="0px 0px 0px 0px" font="--headline3">
							Phone
						</Text>
						<Text margin="0px 0px 0px 0px" min-width="300px">
							+91 98918 02213
						</Text>
					</Override>
					<Override slot="cell-2">
						<Text margin="0px 0px 0px 0px" font="--headline3">
							Email
						</Text>
						<Text margin="0px 0px 0px 0px" min-width="300px">
							info@asvata-olare.net
						</Text>
					</Override>
					<Override slot="Cell 0th" width="30%" margin="0px 0px 0px 25px" />
				</Override>
			</Structure>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});